import { ReactCountryFlag } from 'react-country-flag'

import { cn } from '~/lib/utils'

type Props = {
  name: string
  width: number
  className?: string
}

export function CountryFlag(props: Props) {
  return (
    <ReactCountryFlag
      countryCode={props.name}
      width={props.width}
      height={props.width}
      alt={props.name + ' Flag'}
      className={cn('shrink-0', props.className)}
      style={{
        width: props.width,
        height: 'auto',
      }}
      svg
    />
  )
}
